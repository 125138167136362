<template lang="pug">
q-page(padding)
  .text-h3.text-center.q-mb-xl.q-mt-lg.text-secondary {{ $t('terms_privacy_policy') }}

  .row.text-left.q-mt-lg(style="margin-left: 10vw; margin-right: 10vw")
    .col-12
      .text-h4.text-secondary {{ $t('terms_disclaimer') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('terms_disclaimer_1') }}
      ul.q-ml-none.q-pl-md
        li.text-body1(
          v-for="(disclaimer, index) in messages.terms_disclaimer_2",
          :key="index"
        ) {{ disclaimer }}

    .col-12.q-mt-lg
      .text-h4.text-secondary {{ $t('terms_a_terms_of_use') }}
      .text-body1.q-mt-lg.q-mb-md(
        v-for="(termItem, index) in messages.terms_use",
        :key="index",
        :class="{ 'text-bold': index.endsWith('bold') }"
      ) {{ termItem }}
      
    .col-12.q-mt-lg
      .text-h4.text-secondary {{ $t('terms_privacy_policy') }}
      .text-body1.q-mt-lg.q-mb-md(
        v-for="(termItem, index) in messages.terms_privacy",
        :key="index",
        :class="{ 'text-bold': index.endsWith('bold') }"
      ) {{ termItem }}


    .col-12.q-mt-lg
      .text-h4.text-secondary {{ $t('terms_b_use_of_personal_info') }}
      .text-body1.q-mt-lg.q-mb-md(
        v-for="(termItem, index) in messages.terms_personal",
        :key="index",
        :class="{ 'text-bold': index.endsWith('bold') }"
      ) {{ termItem }}
      ul.q-ml-none.q-pl-md
        li.text-body1(
          v-for="(termItem, index) in messages.terms_b_use_of_personal_info_4_items",
          :key="index"
        ) {{ termItem }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('terms_b_use_of_personal_info_extra') }}

    .col-12.q-mt-lg
      .text-h4.text-secondary {{ $t('terms_c_non_personal_info') }}
      .text-body1.q-mt-lg.q-mb-md(
        v-for="(termItem, index) in messages.terms_non_personal",
        :key="index",
        :class="{ 'text-bold': index.endsWith('bold') }"
      ) {{ termItem }}

    .col-12.q-mt-lg
      .text-h4.text-secondary {{ $t('terms_d_level_of_security') }}
      .text-body1.q-mt-lg.q-mb-md(
        v-for="(termItem, index) in messages.terms_security",
        :key="index",
        :class="{ 'text-bold': index.endsWith('bold') }"
      ) {{ termItem }}

    .col-12.q-mt-lg
      .text-h4.text-secondary {{ $t('terms_e_access_to_your_data') }}
      .text-body1.q-mt-lg.q-mb-md(
        v-for="(termItem, index) in messages.terms_data",
        :key="index",
        :class="{ 'text-bold': index.endsWith('bold') }"
      ) {{ termItem }}

    .col-12.q-mt-lg
      .text-h4.text-secondary {{ $t('terms_f_privileges_and_immunities') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('terms_f_privileges_and_immunities_1') }}

    .col-12.q-mt-lg
      .text-h4.text-secondary {{ $t('f-submission-consent-form') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('terms_s_1') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('terms_s_2') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('terms_s_3') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('terms_s_4') }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('terms_s_5') }}
      ol.q-ml-none.q-pl-md
        li.text-body1(
        v-for="(termItem, index) in messages.submissions_terms",
        :key="index",
        :class="{ 'text-bold': index.endsWith('bold') }"
      ) {{ termItem }}
      .text-body1.q-mt-lg.q-mb-md {{ $t('terms_s_6') }}
      ol.q-ml-none.q-pl-md
        li.text-body1(
        v-for="(termItem, index) in messages.submissions_terms_more",
        :key="index",
        :class="{ 'text-bold': index.endsWith('bold') }"
      ) {{ termItem }}
      .text-body1 {{ $t('terms_s_7') }}


    .col-12.q-mt-lg
      .text-h4.q-mb-xl.q-mt-lg.text-secondary {{ $t('event_privacy_policy') }}
        .text-body1.q-mt-lg.q-mb-md 
          a(
            href="https://solferinoacademy.com/event-privacy-policy/",
            target="_blank"
          ) https://solferinoacademy.com/event-privacy-policy/

      .text-h4.q-mb-xl.q-mt-lg.text-secondary {{ $t('community_guidelines') }}
        .text-body1.q-mt-lg.q-mb-md 
          a(
            href="https://solferinoacademy.com/community-guidelines/",
            target="_blank"
          ) https://solferinoacademy.com/community-guidelines/
</template>

<script>
  export default {
    computed: {
      messages() {
        return this.$i18n.messages[this.$i18n.locale];
      },
    },
  };
</script>
